import React from "react"
import { MarginStyle } from "../../../types"
import "./style.scss"
import { css, jsx } from "@emotion/react"
import { accent } from "../../../values/color"

interface CountButtonProps {
  style?: MarginStyle
  count: number
  onClickIncrease: () => void
  onClickDecrease: () => void
  forEvent?: boolean
  onClickCartButton?: () => void
  disabled?: boolean
}

const root = css`
  align-items: center;
`

const countText = css`
  display: flex;
  width: 70px;
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 1024px) {
    width: 60px;
    height: 35px;
    span {
      font-size: 13px;
    }
  }
`

const countTextWithCart = css`
  display: flex;
  width: 70px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  margin-left: 10px;
  margin-right: 10px;

  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;

  #cart {
    display: block;
  }

  #cartIcon {
    display: none;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
    width: 60px;
    height: 35px;
    justify-content: center;
    padding: 0px;
    #cart {
      display: none;
    }

    #cartIcon {
      display: block;
    }
  }
`

const eventPlusMinusButton = css`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: ${accent};
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: brightness(75%);
  }

  img {
    width: 15px;
    height: 15px;
    margin: 0px;
  }
  @media (max-width: 1024px) {
    width: 35px;
    height: 35px;
  }
`

const eventPlusMinusButtonDisabled = css`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: ${accent};
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  cursor: default;
  opacity: 0.4;

  img {
    width: 15px;
    height: 15px;
    margin: 0px;
  }

  @media (max-width: 1024px) {
    width: 35px;
    height: 35px;
  }
`

const toCartButton = css`
  width: 20px;
  height: 20px;
`

const CountButton = ({
  style,
  count,
  onClickIncrease,
  onClickDecrease,
  forEvent,
  onClickCartButton,
  disabled
}: CountButtonProps) => {
  if (forEvent) {
    return (
      <div className="h-box" css={root}>
        <div
          css={count === 0 ? eventPlusMinusButtonDisabled : eventPlusMinusButton}
          onClick={onClickDecrease}
        >
          <img src={require("../../../images/minus-white.svg")} />
        </div>
        {count != undefined && count > 0 ? (
          <div css={countTextWithCart} onClick={onClickCartButton}>
            <span className="count-with-cart">{count}</span>
            <span className="cart-text" id="cart">
              장바구니
            </span>
            <img
              src={require("../../../images/add-to-cart.svg")}
              css={toCartButton}
              id="cartIcon"
            />
          </div>
        ) : (
          <div css={countText}>
            <span>{count}</span>
          </div>
        )}

        <div css={eventPlusMinusButton} onClick={onClickIncrease}>
          <img className="button-icon" src={require("../../../images/plus-white.svg")} />
        </div>
      </div>
    )
  } else {
    return (
      <div style={style} className="h-box count-button-container">
        <button
          className={"count-button" + (count == 0 ? " disabled" : "")} 
          onClick={onClickDecrease}
          disabled={disabled}
          style={{
            border: "none"
          }}
        >
          <img className="button-icon" src={require("../../../images/minus-white.svg")} />
        </button>
        <div className="count-text-container">
          <span className="count-text">{count}</span>
        </div>
        <button 
          className="count-button" 
          onClick={onClickIncrease}
          disabled={disabled}
          style={{
            border: "none"
          }}
        >
          <img className="button-icon" src={require("../../../images/plus-white.svg")} />
        </button>
      </div>
    )
  }
}

export default CountButton
